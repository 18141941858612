<template>
  <div class="bg-black flex flex-col py-2 sm:px-6 lg:px-8 h-screen">
    <Videos @video-selected="onVideoSelected" :type="type"></Videos>
  </div>
</template>

<script>
import Constants from "../common/Constants";
import {mapMutations} from 'vuex';
import Videos from "../components/Videos.vue";
export default {
  name: "Custom",
  components: {
    Videos
  },
  data(){
    return {
      type: Constants.LIST_TYPE.RICH
    }
  },
  mounted(){
    this.$nextTick(()=>{
      this.UpdateCurrentPageVideosType(this.type); // 更新一下当前的视频类型
    })
  },
  methods:{
    ...mapMutations(['UpdateCurrentPageVideosType']),
    onVideoSelected: function(payload){
      if(payload.loggedIn){
        this.$router.push({
            name:'Watch',params:{id: payload.video.i}
        });
      }else{
        this.$router.push({
            path:'/sign-in'
        });
      }
    }
  }
};
</script>

<style scoped>
body{
  background-color: black;
}
</style>